input.MuiInputBase-input,
input.DatePicker__input,
textarea.MuiInputBase-input {
  width: 100%;
  padding: 1rem 1.3rem;
  box-sizing: border-box;
  background-color: color(color-white);
  border-radius: 5px;
  font-size: 1.5rem;
  font-family: inherit;
}

input.DatePicker__input,
.MuiInputBase-root:not(.default-border):not(.MuiAutocomplete-inputRoot)
  > input.MuiInputBase-input,
.MuiInputBase-root:not(.default-border) > textarea.MuiInputBase-input {
  border: 1px solid color(color-grey-dark);
}

textarea.MuiInputBase-input {
  line-height: 1.7;
  font-size: 1.3rem;
}

input.MuiInputBase-input,
input.DatePicker__input {
  height: $inputHeight;

  &::placeholder {
    font-size: 1.3rem;
  }
}

div.MuiInputBase-multiline {
  padding: 0;
}

div.MuiInput-underline:before {
  border-bottom: 1px solid transparent;
  content: '';
}

.MuiFormControl-root > label {
  @include form-label;
  margin-bottom: 0.7rem;
}

label.custom-label {
  @include form-label;
  display: inline-block;
  margin-bottom: 1rem;
}

p.MuiFormHelperText-root.Mui-error {
  font-size: 1.1rem;
  font-family: inherit;
  font-weight: inherit;
}
