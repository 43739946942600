
.login {
  &__container {
    min-height: 100vh;
    background: {
      image: url('../../img/login-bg.png');
      size: cover;
      position: center;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 7rem;
  }

  &__avatar {
    background-color: color(color-white);
    height: 5rem;
    width: 5rem;
  }

  &__form {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 2rem 0;

    & label {
      font-size: 1.6rem;
      color: color(color-white);
    }

    & > .MuiFormControl-root:first-child {
      margin-bottom: 1.5rem;
    }

    & button[type='submit'] {
      margin-top: 2.5rem;
      font-size: 1.6rem;
      font-family: inherit;
      background: linear-gradient(
        45deg,
        color(color-primary) 30%,
        color(color-primary-light) 90%
      );
    }
  }
}
