body {
  font-family: 'IRANSans', sans-serif, serif;
  font-weight: 400;
  line-height: 1.7;
  color: color(color-dark);
}

.heading-primary {
  font-size: 2.5rem;
  font-weight: 400;
  background-image: linear-gradient(
    to left,
    color(color-primary-dark) 30%,
    color(color-primary-light) 85%
  );
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  display: inline-block;
  letter-spacing: 0.3rem;
  text-shadow: 0.5rem 1rem 2rem rgba(color(color-black), 0.3);
  position: relative;
}

.heading-secondary {
  color: color(color-dark);
  font-size: 2.25rem;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > svg {
    font-size: 4rem;
  }
}

label.MuiTypography-root {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 1.3rem;
  text-align: right;
}
