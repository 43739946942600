@mixin form-label {
  font-size: 1.25rem;
  color: color(color-font);
}

@mixin respond($breakpoint) {
  @if $breakpoint==600 {
    @media only screen and (max-width: 37.5em) {
      @content;
    } //600px
  }

  @if $breakpoint==960 {
    @media only screen and (max-width: 60em) {
      @content;
    } //960px
  }

  @if $breakpoint==1200 {
    @media only screen and (max-width: 75em) {
      @content;
    } //1200px
  }

  @if $breakpoint==1300 {
    @media only screen and (max-width: 81.25em) {
      @content;
    } //1300px
  }

  @if $breakpoint==1800 {
    @media only screen and (min-width: 112.5em) {
      @content;
    } //1800px
  }
}