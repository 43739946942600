.products {
  &__acc-details {
    max-height: 20rem;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;

    .MuiTypography-body1 {
      font-size: 1.3rem;
    }

    .MuiSvgIcon-root {
      font-size: 2.2rem;
    }

    .search-input {
      width: 80%;
      margin-right: 5%;
      border: 1px solid color(color-grey);
      border-radius: 10rem;
      height: 4rem;
      min-height: 4rem;
      overflow: hidden;
    }
  }
}
