.content-box {
  padding: 2.5rem;
  border-radius: 0.4rem;
  overflow: hidden;

  &.p-0 {
    padding: 0;
  }

  &__header {
    margin-bottom: 1.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: color(color-white);
    font-size: 1.3rem;

    & > .MuiButton-contained,
    & > .btn-group > .MuiButton-contained.light {
      background-color: color(color-primary-light);
      color: inherit;

      &:hover {
        background-color: darken(color(color-primary-light), 10);
      }
    }

    & > .btn-group > .MuiButton-contained:not(:last-child) {
      margin-left: 1.5rem;
    }

    & > .btn-group > .MuiButton-contained.blue {
      background-color: color(color-primary);
      color: inherit;

      &:hover {
        background-color: darken(color(color-primary), 10);
      }
    }

    & > .btn-group > .MuiButton-contained.purple {
      background-color: #a339ff;
      color: inherit;

      &:hover {
        background-color: darken(#a339ff, 10);
      }
    }
  }
}
