.h-rule {
  position: absolute;
  left: 0;
  right: 0;
  top: 120%;
  border: 1px solid color(color-grey);

  &::after {
    content: '';
    display: inline-block;
    width: 1rem;
    height: 1rem;
    border: 1px solid color(color-grey);
    border-radius: 50%;
    background-color: color(color-white);
    position: absolute;
    top: -0.5rem;
    left: 50%;
    transform: translateX(-50%);
  }
}
