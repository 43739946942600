.MuiButton-root.MuiButton-contained.btn {
  display: block;
  color: color(color-white);
  font-size: 1.3rem;

  &--light {
    background-color: color(color-primary-light);

    &:hover {
      background-color: darken(color(color-primary-light), 10);
    }
  }
}
