.custom-alert,
.custom-alert-sm {
  .MuiAlert-root {
    padding: 1rem;
    border-radius: 1rem;
    flex-direction: row-reverse;
    justify-content: flex-end;
    background-color: color(color-grey);
    position: relative;

    & > .MuiAlert-action {
      position: absolute;
      right: 0.7rem;
      top: 1rem;
      margin-right: 0;
      margin-left: 0;
      padding-right: 0;
    }

    .MuiAlert-message {
      margin: 0 5rem;

      & > p {
        font-weight: 300;
        word-spacing: 1px;
      }

      & > p:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.custom-alert {
  .MuiAlert-root {
    background-color: color(color-grey);

    .MuiAlert-message {
      & > p {
        font-size: 1.3rem;
      }
    }
  }
}

.custom-alert-sm {
  .MuiAlert-root {
    background-color: color(color-grey-light);

    .MuiAlert-message {
      & > p {
        font-size: 1.1rem;
      }
    }
  }
}
