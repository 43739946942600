.table-container {
  padding: 2rem 0;
}

.patterns-table,
.producers-table,
.users-table,
.calls-table {
  thead > tr > th:nth-child(2) {
    text-align: right !important;
  }
}

.calls-table {
  position: relative;

  .MuiPaper-elevation2 {
    box-shadow: none;
  }

  .MuiToolbar-regular {
    min-height: 0;

    .MuiIconButton-root {
      position: absolute;
      z-index: 100;
      right: 0;
      bottom: -10rem;
    }
  }

  thead.MuiTableHead-root {
    border-bottom: 3rem solid color(color-white);
    position: relative;

    &::after {
      content: '';
      display: block;
      width: 97%;
      height: 1px;
      background-color: color(color-border);
      position: absolute;
      top: 8.25rem;
      left: 0;
    }
  }
}

.custom-table {
  thead.MuiTableHead-root {
    background-color: color(color-grey-light);

    th.MuiTableCell-head {
      font-size: 1.3rem;
      color: color(color-dark);
      text-align: center;
    }
  }

  tbody.MuiTableBody-root {
    td.MuiTableCell-body {
      color: color(color-dark);
      font-size: 1.3rem;
      text-align: center;

      a.MuiLink-root,
      button.MuiButton-text {
        font-weight: 600;
        font-size: inherit;
      }

      .MuiButtonGroup-contained {
        box-shadow: none;
      }

      .MuiButtonGroup-groupedHorizontal {
        border-color: color(color-grey);
        border-radius: 5px;
      }
    }
  }

  &__paginate {
    padding: 1rem 0;
  }
}

.table-heading {
  background-color: color(color-primary);
  color: color(color-white);
  padding: 0.6rem 2.5rem;
  font-size: 1.5rem;
  font-weight: 600;
  word-spacing: 2.5px;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}
