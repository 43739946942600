.custom-dialog {
  position: relative;

  .MuiDialogContent-root {
    padding: 1.5rem 2.2rem;
  }

  .MuiDialog-paper {
    border-radius: 0.5rem;
  }

  .MuiIconButton-root[aria-label='close'] {
    position: absolute;
    top: 1rem;
    right: 1rem;

    .MuiSvgIcon-root {
      font-size: 2rem;
    }
  }

  &--light {
    .MuiDialog-paper.MuiPaper-root {
      background-color: color(color-light);
    }
  }
}
