.photos {
  &__logo-box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    height: 100%;

    & > .MuiAvatar-root {
      width: 10rem;
      height: 10rem;
      border: 1px solid color(color-grey);
      border-radius: 5px;
      box-shadow: $boxShadow-sm;
    }

    & > .dropzone {
      margin-top: 1.5rem;

      & > button {
        padding: 0.7rem 5rem;
        font-size: 1.2rem;
      }
    }
  }

  &__img-box {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    height: 100%;
    width: 80%;
    padding-bottom: 2rem;

    & > .MuiAvatar-root {
      width: 100%;
      height: 15rem;
      border-radius: 5px;
      box-shadow: $boxShadow-sm;
    }

    &--lg > .MuiAvatar-root {
      height: 20rem;
    }

    & > .dropzone {
      margin-top: 1.5rem;

      & > button {
        padding: 0.7rem 5rem;
        font-size: 1.2rem;
      }
    }
  }
}
