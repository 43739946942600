.select-container {
  width: 100%;

  & > label {
    margin-bottom: 0.7rem;
  }

  .MuiSelect-root {
    font-family: inherit;
    font-size: 1.25rem;
  }

  & > .MuiInputBase-root {
    border: 1px solid color(color-grey-dark);
    background-color: color(color-white);
    height: 4rem;
    padding: 1rem 1.3rem;
    border-radius: 5px;
  }

  .MuiAutocomplete-input {
    border: none;
  }

  .MuiOutlinedInput-notchedOutline {
    border-color: color(color-grey-dark);
  }
}
