*,
::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}
html {
  font-size: 62.5%; // 62.5% ~ 10px = 1rem

  @include respond(1800) {
    font-size: 75%;
  }

  @include respond(1300) {
    font-size: 56.25%;
  }

  @include respond(900) {
    font-size: 50%;
  }
}
body {
  background-color: color(color-light);
  box-sizing: border-box;
  direction: rtl;
  overflow-x: hidden;
}

.MuiButton-label {
  color: currentColor;
}

.MuiAccordionSummary-root.Mui-expanded {
  min-height: 5rem;
}

.MuiAccordionSummary-content.Mui-expanded {
  margin: 1.5rem 0;
}

.MuiAvatar-root.avatar-shadow {
  box-shadow: 0 0.4rem 0.8rem 0 rgba(color(color-black), 0.2);
}

.MuiAvatar-root.avatar-bg {
  background-color: color(color-grey);
  border: 1px solid color(color-border);
}

.MuiChip-label {
  font-size: 12px;
}
