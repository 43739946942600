.recharts {
  &-cartesian-axis-ticks {
    font-size: 1.3rem;
  }

  &-wrapper {
    direction: ltr;
  }

  &-xAxis {
    direction: rtl;
  }

  &__tooltip {
    direction: rtl;
    min-width: 12rem;
    padding: 1rem 1.6rem;
    border: 1px solid color(color-border);
    border-radius: 0.3rem;
    box-shadow: $boxShadow-sm;
    background-color: color(color-white);

    &-label {
      font-size: 1.25rem;
      margin-bottom: 0.6rem;
      color: color(color-font);

      & > strong {
        font-size: 1.15em;
      }
    }

    &-value {
      font-size: 1.4rem;
      color: color(color-dark);

      & > strong {
        font-size: 1.1em;
      }

      & > span {
        // color: color(color-font);
        margin-left: 0.4rem;
        font-size: 0.9em;
      }
    }
  }
}
