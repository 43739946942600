.form-container {
  padding: 1.5rem 0 2.5rem;

  &__search-box {
    display: flex;
    align-items: center;

    & > button {
      align-self: flex-end;
      height: 3.99rem;
      margin-bottom: 0.7rem;
      margin-right: -7px;
      font-size: 1.2rem;
      border: 1px solid color(color-grey-dark);
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  input {
    &::placeholder {
      font-size: 1.3rem;
    }
  }
}

.custom-form {
  &__cta {
    margin: 4rem 0;

    & > button {
      width: 20rem;
      padding: 0.7rem 4rem;
      margin: 0 1rem;
      font-size: 1.3rem;
    }
  }
}
