.MuiPaper-root.custom-tabs {
  box-shadow: none;
  border: 1px solid color(color-border);

  .MuiTab-root {
    font-size: 1.5rem;
    font-weight: 500;
    position: relative;

    &:not(:first-child)::after {
      content: '';
      display: inline-block;
      height: 50%;
      border-left: 1px solid color(color-border);
      position: absolute;
      right: 0;
      bottom: 50%;
      transform: translateY(50%);
    }

    &:not(.Mui-selected) {
      color: color(color-dark);
    }
  }
}
