@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(color(color-primary), 0.5);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(color(color-primary), 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(color(color-primary), 0);
  }
}
