.custom-calendar {
  &.no-shadow {
    box-shadow: none;
  }

  &__today {
    background-color: var(--color-light);
  }

  .Calendar__day {
    position: relative;

    &::before {
      content: attr(data-data);
      color: var(--color-grey-dark);
      font-size: 0.7em;
      font-weight: normal;
      position: absolute;
      bottom: 5%;
      right: 10%;
    }
  }
}
