.MuiPaper-elevation1.search-input {
  display: flex;
  align-items: center;
  padding: 2px 4px;

  &--contained {
    padding: 0;
    border: 1px solid color(color-border);
    box-shadow: none;
    margin-bottom: 0.6%;
    overflow: hidden;

    &:focus,
    &:focus-within {
      border: 1px solid color(color-primary);
    }

    & > button {
      align-self: stretch;
      font-size: 1.2rem;
      display: block;
      border-radius: initial;
    }
  }

  & > .MuiInputBase-root {
    flex: 1;

    & > input.MuiInputBase-input {
      border: none;
    }
  }
}
