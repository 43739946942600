.editable-table {
  tbody.MuiTableBody-root {
    td.MuiTableCell-body {
      line-height: 1.8;
    }
  }

  &.reviews-table tbody > tr.MuiTableRow-root.MuiTableRow-hover {
    cursor: initial !important;

    & > td:first-child > button {
      display: none;
    }

    & button[aria-label='reply'].active {
      color: color(color-primary);
    }
  }

  button.MuiIconButton-root {
    padding: 0.5rem 0.2rem;
    border-radius: none;

    &:hover {
      background-color: transparent;
    }
  }

  .MuiTableSortLabel-icon {
    opacity: 1;
  }

  &.box-shadow-none > .MuiPaper-elevation2 {
    box-shadow: none;
  }

  a.MuiLink-root {
    font-weight: 600;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 1.2rem;
    text-align: right;
  }

  .MuiCircularProgress-colorPrimary:not(.custom-progress) {
    color: color(color-primary);
  }
}
