.redux-toastr {
  .top-right {
    width: 38rem;
  }

  .toastr {
    .rrt-title,
    .rrt-text {
      font-family: 'IRANSans';
      text-align: right;
    }

    .rrt-title {
      font: {
        size: 1.8rem !important;
        weight: 400 !important;
      }
      word-spacing: 3px;
    }
    .rrt-text {
      font-size: 1.4rem;
      color: color(color-light);
    }
  }
}
