.u-mt-bg {
  margin-top: 5rem !important;
}
.u-mt-md {
  margin-top: 3rem !important;
}
.u-mt-sm {
  margin-top: 1.5rem !important;
}
.u-mt-tiny {
  margin-top: 0.7rem !important;
}
.u-mb-bg {
  margin-bottom: 5rem !important;
}
.u-mb-md {
  margin-bottom: 4rem !important;
}
.u-mb-sm {
  margin-bottom: 1.5rem !important;
}
.u-mb-tiny {
  margin-bottom: 0.7rem !important;
}

.u-pt-0 {
  padding-top: 0 !important;
}

.u-py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.u-width-100 {
  width: 100% !important;
}

.u-font-md {
  font-size: 1.8rem;
}
.u-font-default {
  font-size: 1.6rem;
}
.u-font-sm {
  font-size: 1.3rem;
}
.u-font-tiny {
  font-size: 1.1rem;
}

.u-text-center {
  text-align: center !important;
}

.border-less {
  border: none !important;
}

.btn-ellipsis {
  max-width: 20rem;
  font-size: 1.3rem;

  & > span.MuiButton-label {
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    white-space: nowrap;
  }
}

.badge-anim {
  & > .MuiBadge-badge {
    box-shadow: 0 0 0 rgba(color(color-primary), 0.5);
    animation: pulse 2.5s infinite;
  }
}

.h-warning {
  margin: 40vh;
  direction: ltr;
  font-size: 3rem;
  text-align: center;
}

.tooltip-text {
  font-size: 1.5em;
}

.loading-container {
  position: absolute;
  top: 40%;
  left: 0;
  right: 0;
  z-index: 1000;
  color: color(color-primary);
  text-align: center;
  display: none;
}

.phone-cell {
  font-size: 1.1em;
  letter-spacing: 0.8px;
  white-space: nowrap;
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  font-weight: 400;

  &.bold {
    font-weight: bold;
  }

  &.clickable {
    cursor: pointer;
    color: color(color-primary);

    &:hover {
      background-color: rgba(33, 150, 243, 0.04);
    }
  }
}

.abs-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
