$inputHeight: 4rem;

$boxShadow-sm: 0px 2px 1px -1px rgba(#000, 0.2),
  0px 1px 1px 0px rgba(#000, 0.14), 0px 1px 3px 0px rgba(#000, 0.12);

$colors: (
  color-primary: #2196f3,
  color-primary-dark: #126bb4,
  color-primary-light: #21cbf3,
  color-green: #04c956,
  color-red: #f21d3d,
  color-orange: #ffba30,
  color-violet: #4d568e,
  color-white: #ffffff,
  color-grey: #e5e5e5,
  color-grey-light: #efefef,
  color-grey-dark: darken(#e5e5e5, $amount: 30),
  color-black: #000,
  color-light: #f7f7f7,
  color-lighter: #fafaf9,
  color-dark: #333,
  color-font: #555,
  color-border: #ebebec
);
// Set CSS variables for colors (root element)
:root {
  @each $name, $color in $colors {
    --#{$name}: #{$color};
  }
  --box-shadow-sm: #{$boxShadow-sm};
}
