.form__radio {
  & > p {
    margin-bottom: 1.3rem;
    @include form-label;
  }

  & > .MuiFormGroup-root {
    flex-direction: row;
    flex-wrap: nowrap;

    & > div.form__radio-group {
      flex: 1;
    }
  }

  &-group {
    display: inline-block;
  }

  &--label-w {
    .form__radio-label {
      background-color: color(color-white);
    }
  }

  &-label {
    display: block;
    text-align: center;
    font-size: 1.3rem;
    cursor: pointer;
    padding: 0.5rem 1rem;
    background-color: color(color-grey-light);
    border-radius: 5px;
    margin: 0 1px;
    transition: all 0.2s;
  }

  &--lg {
    label.form__radio-label {
      padding: 0.7rem 1rem;
      width: 7rem;
    }
  }

  &--spacing {
    div.form__radio-group:not(:first-of-type) {
      margin: 0 2rem;
    }
  }

  &-input {
    display: none;
  }

  &-input:checked ~ &-label {
    color: color(color-white);
    background-color: color(color-primary);
  }

  &--tertiary {
    display: flex;

    & > * {
      flex: 1;
    }

    label.form__radio-label {
      padding: 0.7rem 1rem;
      width: 99%;
    }

    input.form__radio-input:checked ~ label.form__radio-label {
      color: color(color-white);
      background-color: color(color-red);
    }
  }
}
