.RichEditor-root {
  background: color(color-white);
  border: 1px solid color(color-grey-dark);
  font-family: 'IRANSans', serif;
  font-size: 1.4rem;
  padding: 1.5rem;
}

.RichEditor-editor {
  max-height: 40vh;
  height: 30vh;
  overflow-y: auto;
  border-top: 1px solid color(color-grey-dark);
  cursor: text;
  font-size: 1.4rem;
  padding: 1rem 1.5rem;
  margin-top: 1rem;

  .public-DraftEditorPlaceholder-root,
  .public-DraftEditor-content {
    margin: 0 -1.5rem -1.5rem;
    padding: 1.5rem;
  }

  .public-DraftEditor-content {
    min-height: 10rem;
  }

  .RichEditor-blockquote {
    border-right: 5px solid #eee;
    color: #666;
    font-family: 'IRANSans', 'Hoefler Text', 'Georgia', serif;
    font-style: italic;
    margin: 1.6rem 0;
    padding: 1rem 2rem;
  }

  .public-DraftStyleDefault-pre {
    background-color: rgba(color(color-black), 0.05);
    font-family: 'Inconsolata', 'Menlo', 'Consolas', monospace;
    font-size: 1.6rem;
    padding: 2rem;
  }
}

.RichEditor-hidePlaceholder .public-DraftEditorPlaceholder-root {
  display: none;
}

.RichEditor-controls {
  font-family: 'Helvetica', sans-serif;
  font-size: 1.4rem;
  margin-bottom: 5px;
  user-select: none;
}

.RichEditor-styleButton {
  color: #999;
  cursor: pointer;
  margin-right: 1.6rem;
  font-size: inherit;
  padding: 0.5rem;
  border-radius: 5px;
  display: inline-block;

  svg.MuiSvgIcon-root {
    font-size: 2.5rem;
  }

  &:last-child {
    svg.MuiSvgIcon-root {
      transform: rotate(180deg);
    }
  }
}

.RichEditor-activeButton {
  color: color(color-primary);
}
